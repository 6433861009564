import React from 'react';

import './Signup.css';

const REACT_COMPONENTS_FORM_ID = '1181861';
const OVERREACTED_FORM_ID = '812047';

class Signup extends React.Component {
  render() {
    let form,
      { cta } = this.props;
    switch (cta) {
      case 'react':
        form = {
          id: REACT_COMPONENTS_FORM_ID,
          title: 'Learn to Build Resilient React Components',
          subTitle:
            'Get a one week email course and learn how I think about writing React components based on 4 Principles.',
          buttonText: 'Start Learning',
        };
        break;
      default:
        form = {
          id: OVERREACTED_FORM_ID,
          title: 'Subscribe to the Newsletter',
          subTitle: 'Subscribe to get my latest content by email.',
          buttonText: 'Subscribe',
        };
    }
    return (
      <form
        action={`https://app.convertkit.com/forms/${form.id}/subscriptions`}
        className="seva-form formkit-form"
        method="post"
        min-width="400 500 600 700 800"
        style={{
          boxShadow: 'var(--form-shadow)',
          backgroundColor: 'var(--bg)',
          borderRadius: '6px',
        }}
      >
      </form>
    );
  }
}

export default Signup;
